export interface LatLng
{
    latitude: number;
    longitude: number;
}

export interface Bounds
{
    sw: LatLng;
    ne: LatLng;
}


export interface GeoJsonData
{
    name: string;
    source: string;
}

export enum MapChartType
{
    FOCA,
    Generic
}

export interface MapChart
{
    name: string;
    visible: boolean;
    type: MapChartType;
}