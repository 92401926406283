import { InvoliErrorCategory } from './involi-error-category.js';

export type ErrorContext<T extends object = {}> = Record<string, string | number | boolean | string[]> & T;

export class InvoliError<Data = unknown> extends Error
{
    constructor(public meta: ErrorMeta, public context: ErrorContext, public cause?: InvoliError, public data?: Data)
    {
        super(meta.title, { cause });
    }

    is<T extends object, D>(factory: ErrorFactory<T, D>): this is InvoliError<D>
    {
        const factoryMeta: ErrorMeta<any> | undefined = (<any>factory)[metaAttr] as ErrorMeta<any> | undefined;
        return !!factoryMeta && factoryMeta.code == this.meta.code;
    }
}

export interface ErrorMeta<T extends object = {}>
{
    category: InvoliErrorCategory;
    code: string;
    title: string;
    detail?: (error: InvoliError) => string;
    requiredContextFields?: Fields<T>;
}

export interface Fields<T> {}
export function fields<T extends object>(): Fields<T> { return {}; }

const codeRegex = /^[a-z0-9-]+$/;
const metaAttr = Symbol();
export type ErrorFactory<T extends object, Data> = (context?: ErrorContext<T>, cause?: unknown, data?: Data) => InvoliError<Data>;

const errorCodes = new Set<string>();
export function makeError<Data = void, T extends object = {}>(meta: ErrorMeta<T>): ErrorFactory<T, Data>
{
    if(!codeRegex.test(meta.code))
        throw new Error(`Invalid error code format for "${meta.code}", expected: [a-z-]+`);
    if(errorCodes.has(meta.code))
        throw new Error(`Duplicate error code "${meta.code}"`);
    errorCodes.add(meta.code);
    const factory = (context?: ErrorContext<T>, cause?: unknown, data?: Data): InvoliError<Data> => {
        return new InvoliError<Data>(meta, context ?? {}, cause ? wrapExternalError(cause) : undefined, data);
    };
    Object.defineProperty(factory, metaAttr, { value: meta, enumerable: false });
    return factory;
}

export function isInvoliError<T extends object, D>(error: unknown, factory: ErrorFactory<T, D>): error is InvoliError<D>
{
    if(!(error instanceof InvoliError))
        return false;
    return error.is(factory);
}

const externalError = makeError({
    category: InvoliErrorCategory.InternalServerError,
    code: 'external-error',
    title: 'an external error has been thrown',
    requiredContextFields: fields<{ externalMessage: string }>()
});

export const internalServerErrorCode = 'internal-server-error';
export const internalServerError = makeError({
    category: InvoliErrorCategory.InternalServerError,
    code: internalServerErrorCode,
    title: 'Internal server error'
});

export const unauthorized = makeError({
    category: InvoliErrorCategory.Unauthorized,
    code: 'unauthorized',
    title: 'Unauthorized'
});

export function wrapExternalError(error: unknown): InvoliError
{
    if(error instanceof InvoliError)
        return error;

    let cause: InvoliError | undefined;
    if(error instanceof Error && error.cause !== undefined)
    {
        if(error.cause instanceof InvoliError)
            cause = error.cause;
        else
            cause = wrapExternalError(error.cause);
    }
    return externalError({
        externalMessage: error instanceof Error ? error.message : JSON.stringify(error),
        stack: error instanceof Error ? JSON.stringify(error.stack) : 'no stack available'
    }, cause);
}
