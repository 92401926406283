import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfig, API_CONFIG } from '../api';
import { ApiClient } from '../core/api-client';
import { ApiStatusService } from '../core';
import { Observable } from 'rxjs';
import { AffixInstanceConfiguration, AffixInstanceMetrics, AffixRecordingInstanceConfiguration, AffixRecordingInstanceUnrestrictedConfiguration, AffixRecordingMetrics, DataRecordingEntity, DataRecordingLimits } from '@involi/api-shared';

@Injectable({
    providedIn: 'root'
})
export class AffixAgentApiService extends ApiClient
{
    private apiUrl: string;

    constructor(@Inject(API_CONFIG) config: ApiConfig,
                http: HttpClient,
                apiStatus: ApiStatusService)
    {
        super(apiStatus, http, 'Affix agent');
        this.apiUrl = `${config.involiApiUrl}/affix`;
    }

    getInstances(): Observable<DataRecordingEntity[]>
    {
        return this.get<DataRecordingEntity[]>(`${this.apiUrl}/instance`);
    }

    getAllInstances(): Observable<DataRecordingEntity[]>
    {
        return this.get<DataRecordingEntity[]>(`${this.apiUrl}/instance/all`);
    }

    runInstance(name: string, affixConfiguration: string): Observable<any>
    {
        const request: AffixInstanceConfiguration = {
            name,
            affixConfiguration
        };
        return this.post<any>(`${this.apiUrl}/instance`, request);
    }

    scheduleInstance(name: string, affixConfiguration: string, start: Date, end: Date): Observable<any>
    {
        const request: AffixInstanceConfiguration = {
            name,
            affixConfiguration,
            start,
            end
        };
        return this.post<any>(`${this.apiUrl}/instance`, request);
    }

    runUnrestrictedRecording(affixConfiguration: AffixRecordingInstanceUnrestrictedConfiguration): Observable<any>
    {
        return this.post<any>(`${this.apiUrl}/instance/record/unrestricted`, affixConfiguration);
    }

    runRecording(affixConfiguration: AffixRecordingInstanceConfiguration): Observable<any>
    {
        return this.post<any>(`${this.apiUrl}/instance/record`, affixConfiguration);
    }

    editRecording(id:string, affixConfiguration: AffixRecordingInstanceConfiguration): Observable<void>
    {
        return this.put<void>(`${this.apiUrl}/instance/${id}/record`, affixConfiguration);
    }

    editUnrestrictedRecording(id:string, affixConfiguration: AffixRecordingInstanceConfiguration): Observable<void>
    {
        return this.put<void>(`${this.apiUrl}/instance/${id}/record/unrestricted`, affixConfiguration);
    }

    editInstance(id: string, name: string, affixConfiguration: AffixInstanceConfiguration): Observable<void>
    {
        return this.put<void>(`${this.apiUrl}/instance/${id}`, affixConfiguration);
    }

    stopInstance(id: string): Observable<void>
    {
        return this.post<void>(`${this.apiUrl}/instance/${id}/stop`, {});
    }

    stopUnrestrictedInstance(id: string): Observable<void>
    {
        return this.post<void>(`${this.apiUrl}/instance/${id}/stop/unrestricted`, {});
    }

    startInstance(id: string): Observable<void>
    {
        return this.post<void>(`${this.apiUrl}/instance/${id}/start`, {});
    }

    startUnrestrictedInstance(id: string): Observable<void>
    {
        return this.post<void>(`${this.apiUrl}/instance/${id}/start/unrestricted`, {});
    }

    discardInstance(id: string): Observable<void>
    {
        return this.post<void>(`${this.apiUrl}/instance/${id}/discard`, {});
    }

    discardUnrestrictedInstance(id: string): Observable<void>
    {
        return this.post<void>(`${this.apiUrl}/instance/${id}/discard/unrestricted`, {});
    }

    getInstanceMetrics(id: string): Observable<AffixInstanceMetrics>
    {
        return this.get<AffixInstanceMetrics>(`${this.apiUrl}/instance/${id}/metrics`);
    }

    getUnrestrictedInstanceMetrics(id: string): Observable<AffixInstanceMetrics>
    {
        return this.get<AffixInstanceMetrics>(`${this.apiUrl}/instance/${id}/metrics/unrestricted`);
    }

    getInstanceRecordingMetrics(id: string): Observable<AffixRecordingMetrics>
    {
        return this.get<AffixRecordingMetrics>(`${this.apiUrl}/instance/${id}/recording-metrics`);
    }

    getUnrestrictedInstanceRecordingMetrics(id: string): Observable<AffixRecordingMetrics>
    {
        return this.get<AffixRecordingMetrics>(`${this.apiUrl}/instance/${id}/recording-metrics/unrestricted`);
    }

    getInstanceLimits(): Observable<DataRecordingLimits>
    {
        return this.get<DataRecordingLimits>(`${this.apiUrl}/instance/limits`);
    }
}